@use "sass:map";

$navbarHeight: clamp(50px, 11vw, 58px);
$navbarHeightTop: 90px;
$colsNumber: 24;

$breakpoints: (
    xs: 0,
    sm: 480px,
    md: 768px,
    lg: 1024px
);
