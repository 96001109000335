@import "../../../../styles//variables.scss";

.section {
    @include container;

    &.white {
        background: #fff;
    }
    &.grey {
        background: #f0f0f0;
    }
    &.primary {
        background: $primary;
        border-bottom: 20px solid $primaryLight;
        color: $light1;

        p {
            &:before {
                background: #fff;
            }
        }
        
    }

    &.paddingY {
        padding-top: 40px;
        padding-bottom: 50px;
    }

    &.isNarrow {
        @include container(2);
    }
}
