.socialsList {
    padding: 5px 0;
    .title {
        font-weight: 400;
        font-size: 1.3rem;
        margin-bottom: 3px;
    }
    .item {
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        padding: 2px 0;
        font-weight: 300;
        svg {
            margin-right: 5px;
        }
    }
}