@use "sass:map";
@import "./slick.scss";
@import "./dims.scss";

// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700;900&display=swap');
// @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;1,400&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  background: #fff !important;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  font-weight: 300;
  font-size: 100%;
}

@media screen and (max-width: map.get($breakpoints, "md")) {
  html {
    font-size: 87.5%;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.leaflet-pane {
  z-index: 1 !important;

}

.leaflet-container {
  width: 100%;
  height: 100%;

  .leaflet-div-icon {
    border: none;
    background: transparent;
    font-size: 1rem;
  }

  .leaflet-top {
    z-index: 2 !important;
  }
}
