@import "../../../../styles/variables.scss";

.toggleBtn {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    border: 0;
    cursor: pointer;

    svg {
        z-index: 1;
        path {
            stroke: $primary;
        }
    }

}

.isOpen {
    svg {
        z-index: 1;
        path {
            stroke: $light1;
        }
    }
}