@import "../../../../../styles//variables.scss";

.btn {
    cursor: pointer;
    border: none;
    padding: 8px 15px;
    font-size: 1.2rem;
    border-radius: $radius;
    font-family: "Roboto";
    font-weight: 300;
    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}

.btnIcon {
    margin-left: 5px;
}

.btnPrimary {
    background: $primary;
    color: $fontLight;
    transition: background 0.3s;
    &:hover {
        background: $primaryLight;
    }
}

.btnText {
    text-transform: uppercase;
    background: none;

    transition: background 0.3s;
    &:hover {
        background: rgba(0,0,0,0.05);
    }
}

.btnLink {
    color: $linkColor;
    padding: 0;
    border: 0;
    font-size: 1rem;
    display: flex;
    align-items: center;
    background: transparent;
}

.small {
    padding: 5px 15px;
    font-size: 1rem;
}

.big {
    padding: 8px 15px;
    font-size: 1.4rem;
}

.closeBtn {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    width: 30px;
    height: 30px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: $radius;
    cursor: pointer;
    transition: 0.3s background;
    &:hover {
        background: rgba(0, 0, 0, 0.08);
    }
}
