@import "../../../../../styles/variables.scss";

.label {
    color: #000;
    margin-bottom: 2px;
    margin-top: 8px;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    &.withError {
        color: $errorColor;
    }
    &.disabled {
        opacity: 0.6;
    }
}

.requiredAsterix {
    color: $errorColor;
    position: absolute;
    top: -1px;
    left: -5px; 
    font-size: 0.8rem;
}
