@import "../../../../../styles/variables.scss";

.inputWrapper {
    display: flex;
    flex-direction: column;
}
.selectWrapper {
    position: relative;
}

.input, .textarea {
    @extend %input-field;
    width: 100%;
}

.inputItemWrapper {
    width: 100%;
    position: relative;

    .inputButton {
        position: absolute;
        right: 10px;
        top: 0;
        // transform: translateY(-45%);
        line-height: 1;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 4px;
        cursor: pointer;
    }
}

.withError {
    border-radius: $radius;
    border: 1px solid $errorColor;
}

.withSelectError {
    border-radius: $radius;
    border: 1px solid blue;
}

.selectLabel {
    display: flex;
    flex-direction: row;
}

.select {
    [class$="-control"] {
        @extend %input-field;
        padding: 0 7px;
    }

    [class$="-ValueContainer"] {
        padding: 0;
        margin: 0;
    }
    [class$="-Input"] {
        padding: 0;
        margin: 0;
        min-height: fit-content;
    }
    [class$="-indicatorContainer"] {
        padding: 0 8px;
        margin: 0;
    }
    [class$="-menu"] {
        z-index: 3;
    }
    [class$="-indicatorSeparator"] {
        display: none;
    }
    [class$="-indicatorContainer"] {
        svg { 
            color: $primary;
            
        }
    }
    
    &.isSelectError {
        [class$="-control"] {
            border: 1px solid $errorColor;
        }
    }
}

