@use "sass:map";

@mixin grid {
    display: grid;
    grid-template-columns: repeat($colsNumber, 1fr);
    grid-gap: 10px;
}
@mixin row {
    grid-column: span $colsNumber;
}

@mixin col($width: 1, $fullTo: "xs") {
    grid-column: $width span;

    @media screen and (max-width: map.get($breakpoints, $fullTo)) {
        grid-column: span $colsNumber;
    }
}

@mixin container($amount: 1) {
    $xPadding: $amount * 5vw;

    padding-left: $xPadding;
    padding-right: $xPadding;
    @media screen and (max-width: map.get($breakpoints, "md")) {
        $xPadding: 10px;
        padding-left: $xPadding;
        padding-right: $xPadding;
    }
}

@mixin button {
    padding: 10px 30px;
}

%input-field {
    background: #fff;
    border-radius: $radius;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    padding: 7px;
    font-size: 0.95rem;
    line-height: 1.25;
    min-height: 2.375rem;
    font-weight: 300;
    font-family: "Roboto";

    &:disabled {
        cursor: not-allowed;
        opacity: 0.75;

        &::placeholder {
            color: #bbb;
        }
    }
}

%card {
    position: relative;
    border-radius: $radius;
    margin-bottom: 15px;
    background: #fefefe;
    box-shadow: $boxShadow;
}

$header: (
    h1: 3.2rem,
    h2: 3rem,
    h3: 2.2rem,
    h4: 1.8rem,
    h5: 1.2rem,
);

@mixin headerText($size: "h1") {
    font-size: map.get($header, $size);
    font-weight: 700;
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 22px;
    margin-top: 0;
    &:before {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        width: calc(230px * calc(map.get($header, $size) / 3.2rem));
        height: calc(11px * calc(map.get($header, $size) / 3.2rem));
        background: #000;
    }
}

@mixin tileList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}
