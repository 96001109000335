@import "../../../../../styles//variables.scss";

.checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .checkboxLabel {
        > div {
            > a {
                color: $linkColor;
                // margin: 
            }
            // display: flex;
            // flex-direction: row;
        }
    }
}
