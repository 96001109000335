@use "sass:map";
@import "../../../../../styles//variables.scss";

.header {
    @include container;
    margin-bottom: 80px;
    margin-top: 80px;
    font-weight: bold;
    @media screen and (max-width: map.get($breakpoints, "md")) {
        display: none;
    }
}

.formWrapper {
    @include container;
    margin-bottom: 4vw;
    background: $primary;
    padding-bottom: 10px;
    margin-top: 40px;
    border-top: 10px solid transparent;
    border-bottom: 20px solid $primaryDark;
}

.placeholder {
    position: relative;
    color: $light1;
    width: 100%;
}

.searchForm {
    display: grid;
    grid-template-columns: repeat(3, 2fr) 1fr;
    grid-gap: 10px 20px;
    padding: 30px 20px;
    border-radius: $radius;
    box-shadow: $boxShadowBottom;
    margin-top: -40px;
    margin-bottom: 10px;
    //todo color:
    background: #eee;

    @media screen and (max-width: map.get($breakpoints, "md")) {
        grid-template-columns: 1fr;
        margin-top: -60px;
        padding: 14px;
    }
}

.selectIcon {
    margin-right: 10px;
    color: $primary;
    font-weight: 900;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}
