@use "sass:map";
@import "../../../../styles/variables.scss";

.websiteIntro {
    @include container;
    background-image: url("/static/front-view-car.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 300px;
    padding-bottom: 8vw;

    @media screen and (max-width: map.get($breakpoints, "md")) {
        background-position: bottom right;
    }
}

.header {
    padding-top: calc(2vh + 4vw);
}