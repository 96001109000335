@import "../../../../styles//variables.scss";

$base: #263238;
$lite: #fff;
$brand: $primary;
$size: 48px;

.loader {
    width: $size;
    height: $size;
    border: 3px solid $lite;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    animation: rotation 1s linear infinite;
    &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: $size - 8px;
        height: $size - 8px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-bottom-color: $brand;
    }
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.isFullPage {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1003;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
