@use "sass:map";
@import "../../../../styles/variables.scss";

.slideFramePopup {
    position: fixed;
    right: 0;
    bottom: -15px;
    padding: 15px;
    width: clamp(310px, 100vw, 360px);
    min-height: 15vh;
    background: #fff;
    border: 1px solid #eee;
    border-radius: $radius;
    z-index: 5;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.15);
    
    transform: translateY(100%);
    transition: transform .3s;

    .slideFramePopupClose {
        position: absolute;
        top: 4px;
        right: 4px;
        background: #fff;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
        cursor: pointer;
    }

    .slideFramePopupChildren {
        max-height: 80vh;
        padding: 10px 0;
        overflow-y: auto;
    }

    .title {
        font-weight: 400;
        font-size: 1.3rem;
        box-shadow: 0 1px 1px -1px rgba(0,0,0,0.2);
        padding-bottom: 2px;
    }

    &.isOpen {
        transform: translateY(0px);
    }

    @media screen and (min-width: map.get($breakpoints, "md")) {
        right: 10px;
    }
}
