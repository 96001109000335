.mobileMenuItem {
    // font-family: Roboto;
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0em;
    text-align: left;
    padding: 3px 0;
}
