@import "../../../../../styles//variables.scss";

$gap: 20px;

.form {
    margin: 3vw auto;
    width: clamp(310px, 100%, 960px);
    padding: 5px;
}

.formFooter {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .formFooterButton {
        display: flex;
        justify-content: flex-end;
    }
}


.row {
    @include grid;
    @include row;
}

.col12 {
    @include col(12, "sm");
}

.formRow {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-column-gap: $gap;

    &.moreInputs {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
}


.socialHeader {
    font-size: 1.4rem;
}

.priceRow {
    display: grid;
    grid-template-columns: minmax(100px, 8.5vw) repeat(2, minmax(70px, 6vw)) 40px;
    grid-gap: $gap * 0.5;
    .closeButton {
        padding-top: 32px;
    }
}

.sectionGap {
    padding-top: 10px;
}

.contactForm {
    padding: 0px;
}

.errorBox {
    border: 1px solid $errorColor;
    color: $errorColor2;
    padding: 10px;
    margin: 10px 0;
}

.textRow {
    margin-top: 10px;
    display: flex;
    font-weight: 400;
    button {
        margin-left: 5px;
    }
}

.mapWrapper {
    margin: 20px 0 0 0;
    width: 300px;
    height: 200px;
}