@import "../../../../styles/variables.scss";

$padding: 20px;

.drawer {
    width: 100%;
    position: fixed;
    right: 0;
    top: 0;
    color: $light1;
    height: 100%;
    overflow-y: auto;
    z-index: 1001;
    transition: height 0.5s;
    background: $primary;
    border-bottom: 20px solid $primaryDark;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    //primaryDark
}

.navOpen {
    // padding-top: $navbarHeightTop;
}

.drawerHeader {
    @include container;

    height: $navbarHeight;
    transition: height 0.5s;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navHeaderOpen {
    height: $navbarHeightTop;
}

.childrenContainer {
    @include container;
}

.sideDrawerFooter {
    @include container;
    background: $primaryLight;
    padding-top: $padding;
    padding-bottom: $padding;
    display: flex;
    justify-content: space-between;


    .drawerFooter {
        display: flex;
        flex-direction: column;

        .drawerFooterTitle {
            font-size: 1.2rem;
            margin-bottom: 4px;
        }

        .drawerFooterList {
            font-size: 2rem;
            line-height: 1; 
            svg {
                margin-right: 7px;
            }
        }
    }
}

.accountCtrl {
    text-align: right;
    svg {
        margin-left: 7px;
        margin-right: 0px !important;
    }
}