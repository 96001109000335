@import "../../../../styles//variables.scss";

.contactWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.directContact {
    display: flex;
    flex-direction: row;
}

.contactRow {
    margin: 2px 0;
    display: flex;
    align-items: center;
    font-size: 15px;
    position: relative;
    height: 50px;
    border-radius: $radius;
    transition: background 0.3s;
    cursor: pointer;
    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
    svg {
        width: 26px;
        height: 26px;
        padding: 3px;
    }
}

.tooltip {
    color: $light1;
    background: rgba(0, 0, 0, 0.8);
    padding: 12px;
    position: absolute;
    bottom: calc(100% - 5px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: $radius;
}

.contactList {
    padding: 5px 0;
    .title {
        font-weight: 400;
        font-size: 1.3rem;
        margin-bottom: 3px;
    }
    .item {
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        padding: 2px 0;
        font-weight: 300;
        svg {
            margin-right: 5px;
        }
    }
}
