@import "../../../../../styles/variables.scss";

$uploadWidth : 100%;

.wrapper {
    height: 100%;
    display: flex;
    justify-content: flex-start;
}

.controller {
    width: $uploadWidth;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.btnWrapper {
    position: relative;
    margin-bottom: 0;
}
.input {
    padding: 5px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    width: $uploadWidth;
}

.inputDesign {
    @extend %input-field;
    cursor: pointer;
}

.photo {
    width: 60px;
    height: 60px;
    object-fit: cover;
    position: absolute;
    bottom: 1px;
    right: 1px;
    transition: transform .3s;
    border-radius: $radius;
    &:hover {
        transform: scale(2);
    }
}

.withError {
    border-radius: $radius;
    border: 1px solid $errorColor;
}


.photoList {
    position: relative;
}