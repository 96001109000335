$primary: #0D31C2;
$primaryLight: #1E42DB;
$primaryDark: #111D4F;
$primaryColor5: #00A3FF;
$primaryColor6: #00CCFF;
$linkColor: #007AFF;

$errorColor: #E50000;
$errorColor2: #E44321;
$successColor: #3AB54B;
$successColor2: #00FC01;

$fontLight: #fff;
$light1: #F8F8F8;
$light2: #E9E9E9;
$light3: #D9D9D9;


$dark: #111;
$dark2: #1E1E1E;
$dark3: #3C3C3C;
$dark4: #4D4D4D;

$radius: 5px;

$boxShadow: 5px 5px 10px -8px rgba(0, 0, 0, 0.4), -5px 5px 10px -8px rgba(0, 0, 0, 0.4);
$boxShadowBottom: 5px 0 10px -8px rgba(0, 0, 0, 0.8), -5px 0 10px -8px rgba(0, 0, 0, 0.8), 0px 5px 10px -8px rgba(0, 0, 0, 0.8);

@import "./dims.scss";
@import "./utils.scss";
