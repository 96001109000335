.searchBoxAdvantages {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-column-gap: 10px;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;

    .bullet {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            margin-right: 4px;
            margin-top: 1px;
        }
    }
}
