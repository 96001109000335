.logo {

    cursor: pointer;
    font-size: 1.2rem;
    color: #000;

    &.white {
        color: #fff;
    }
    &Img {
        width: 140px;
    }

    h1 {
        padding: 0;
        margin: 0;
        line-height: 1;
    }
}
