@import "../../../../styles//variables.scss";


.h1 {
    @include headerText("h1");
}

.h2 {
    @include headerText("h2");
}

.h3 {
    @include headerText("h3");
}

.h4 {
    @include headerText("h4");
}

.h5 {
    @include headerText("h5");
}

.h1, .h2, .h3, .h4, .h5 {

    &.isBold {
        font-weight: 700;
    }
}

.topText {
    margin: 0;
    padding-bottom: 5px;
    font-family: "Poppins";
    font-weight: 300;
}

.spaceBottom {
    margin-bottom: 24px;
}

.spaceTop {
    margin-top: 20px;
}