@use "sass:map";
@import "../../../../styles/variables.scss";

.navbar {
    @include container;
    @include grid;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #000;
    z-index: 1000;
    height: $navbarHeight;
    transition: height .5s;
}

.onTop {
    height: $navbarHeightTop;
}

.onScrolled {
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.2);
}

.navbarButtons {
    display: flex;
    column-gap: 10px;
    align-items: center;

    @media screen and (max-width: map.get($breakpoints, "md")) {
        display: none;
    }
}

.mobile {
    @media screen and (min-width: map.get($breakpoints, "md")) {
        display: none;
    }
}