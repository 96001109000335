@import "../../../../styles//variables.scss";

.accordion {
    margin-bottom: 20px;
    .accordionTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 10px;
        border-bottom: 1px solid #efefef;
        padding: 2px 0;
        svg  {
            color: $primary;
            font-size: 1.5rem;
        }
    }
    .accordionMore {
        height: 0;
        opacity: 0;
        transition: all .3s;
        padding-bottom: 6px;
    }

    &.isOpen {
        .accordionMore {
            height: 100%;
            opacity: 1;
        }
    }

    &.isAlwaysOpen {
        .accordionTitle {
            cursor: default;
        }
    }
}
