@use "sass:map";
@import "../../../../styles//variables.scss";

.carList {
    & > div {
        margin-bottom: calc(20px + 1vh + 1vw);
    }

    &.small {
        @include container(2);
    }

    &.normal {
        @include container;
    }

    &.big {
    }

    &.tiles {
        @include tileList;
    }
}
