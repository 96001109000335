@use "sass:map";
@import "../../../../styles//variables.scss";

.emptyList {
    border: 1px solid $primary;
    border-radius: $radius;
    text-align: center;
    font-weight: 300;

    &.big {
        padding: 1vh 1vw;
        font-size: 1.4rem;
    }

    &.normal {
        padding: 0.7vh 0.5vw;
        font-size: 1.1rem;
    }

    .additionalLabel {
        font-weight: 100;
        font-size: 0.9em;
    }
}
